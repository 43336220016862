import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";
import WashSymbols from "../components/WashSymbols";

interface Props {}

const ONasPage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Úvod" />
      <h1>Úvod</h1>
      <p className="mb-3 text-justify">
        Vítáme Vás na stránkách společnosti ETKA labels, s.r.o.
      </p>
      <p className="mb-3 text-justify">
        Jsme rodinná firma zaměřená na výrobu textilních etiket, papírových
        visaček a podobných produktů, které jsou dnes nezbytnou součástí každého
        textilního výrobku.
      </p>
      <p className="mb-3 text-justify">Na trhu působíme již od roku 1991.</p>{" "}
      <p className="mb-3 text-justify">
        Naše nabídka je orientována na všechny textilní výrobce – od samostatné
        švadleny po velké výrobní společnosti. Je naší snahou nabídnout Vám
        rozumné dodací lhůty, stabilní nízké ceny, dobrou kvalitu a vstřícné
        jednání. Můžeme Vám dodat etikety, které vyžaduje zákonná povinnost –
        zák. 634/1992 Sb., nařízení Rady EU 1007/2001 (údaje o materiálovém
        složení ,ošetřovací informace, atd), stejně tak etikety nebo visačky,
        které dotváří design výrobku. Z hlediska výše zmíněných zákonů by na
        etiketě měly být uvedeny následující údaje – identifikaci výrobce nebo
        dovozce, materiálové složení v procentech, názvy vláken v češtině a bez
        zkratek, návod na ošetřování – obvylke symboly. Tyto informace musí být
        trvale spojeny s výrobkem po dobu jeho životnosti.
      </p>{" "}
      <p className="mb-3 relative font-bold z-20 text-justify">
        Všeobecně známé a běžně používané ošetřovací symboly jsou v České
        republice zapsány jako Ochraná známka č. 849 319. Majitelem této
        ochranné známky je mezinárodní asociace Ginetex, správcem pro Českou
        republiku je společnost Sotex Ginetex CZ. Používání a vysvětlení
        ošetřovacích symbolů je popsáno v mezinárodní normě ISO 3758. Podrobné
        informace najdete na stránkách www.sotex.cz
      </p>
      {/* <div className="z-0 flex justify-center text-justify w-full"> */}
      <StaticImage
        layout="fullWidth"
        src="../images/o-nas/bg.png"
        alt="Labels"
      />
      {/* <div className="bg-gradient-to-b from-white h-100 inset-0 absolute" /> */}
      {/* </div> */}
    </Layout>
  );
};

export default ONasPage;
